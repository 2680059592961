import { StrapiTextualProps } from '@/features/strapi/components/textual';
import { StrapiPartnerReviewCardProps } from '@/features/strapi/components/partner-review-card';

// @ts-ignore
import partnerImage from '../assets/partners/carolinImage.png';
// @ts-ignore
import verbalImage from '../assets/partners/verbalvisual.png';
// @ts-ignore
import visuallyImage from '../assets/vilogo.png';
// @ts-ignore
import rebuyimage from '../assets/rebuy.png';

type MediaType = 'image' | 'video';

interface ComparisonEasyOfUseItem {
  media: {
    type: MediaType;
    src: string;
  };
  text: StrapiTextualProps;
}

interface ComparisonByDataContentItem {
  exampleA: string;
  exampleB: string;
}

interface ComparisonByFeatureContentItem {
  exampleA: boolean | string;
  exampleB: boolean | string;
}

interface ComparisonByDataHeaders {
  text: string;
  styles: StrapiTextualProps;
}

export interface ComparisonPageContent {
  ComparisonByDataHeaders: ComparisonByDataHeaders[];
  ComparisonByDataContent: {
    content: Record<string, ComparisonByDataContentItem>;
  };
  ComparisonProductsAndFeaturesSection: {
    title: StrapiTextualProps;
  };
  ComparisonByFeatureHeaders: ComparisonByDataHeaders[];
  ComparisonByFeatureContent: {
    features: Record<string, ComparisonByFeatureContentItem>;
  };
  ComparisonEasyOfUse: ComparisonEasyOfUseItem[];
  ComparisonEasyOfUseSecrionTitle: {
    title: StrapiTextualProps;
    subTitle: StrapiTextualProps;
  };
  ComparisonSectionHeader: {
    title: StrapiTextualProps;
  };
}

export const ComparisonNavChips = {
  mobile: [
    {
      text: `Visually VS Dynamic Yield`,
      href: `/public/comparison/dy`,
    },
    {
      text: `Visually VS Rebuy`,
      href: `/public/comparison/rebuy`,
    },
    {
      text: `Visually VS Intelligems`,
      href: `/public/comparison/intelligems`,
    },
    {
      text: `Visually VS Shopift`,
      href: `/public/comparison/shoplift`,
    },
  ],
  desktop: [
    {
      text: `Visually VS Dynamic Yield`,
      href: `/public/comparison/dy`,
    },
    {
      text: `Visually VS Rebuy`,
      href: `/public/comparison/rebuy`,
    },
    {
      text: `Visually VS Intelligems`,
      href: `/public/comparison/intelligems`,
    },
    {
      text: `Visually VS Shopift`,
      href: `/public/comparison/shoplift`,
    },
    {
      text: `Partners`,
      href: `/public/partners`,
    },
    {
      text: `Pricing`,
      href: `/pricing`,
    },
  ],
  styles: {
    text: {
      size: `1.5rem`,
      weight: `normal`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.75,
      style: { cursor: `pointer` },
    } as StrapiTextualProps,
  },
};

export const ComparisonTableTextStyles = {
  title: {
    size: `2rem`,
    weight: `bold`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.8,
  } as StrapiTextualProps,
  columnTitle: {
    size: `2rem`,
    weight: `bold`,
    lineHeight: `1.2`,
    mobileMultiplier: 0.8,
  } as StrapiTextualProps,
  feature: {
    size: `1.8rem`,
    weight: `light`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.63,
  } as StrapiTextualProps,
  contentText: {
    size: `1.8rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.63,
    style: {
      textAlign: `center`,
      fontWeight: `300`,
    },
  } as StrapiTextualProps,
  contentTextMobile: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.4`,
    mobileMultiplier: 0.55,
    style: {
      textAlign: `left`,
    },
  } as StrapiTextualProps,
  featureInCell: {
    size: `2.6rem`,
    weight: `bold`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.55,
  } as StrapiTextualProps,
};

export const ComparisonHeaderContent = {
  title: {
    size: `5.8rem`,
    weight: `bold`,
    text: `Visually`,
    lineHeight: `1.3`,
    mobileMultiplier: 0.68,
    mobileText: `Visually`,
    smMobileMultiplier: 0.58,
  } as StrapiTextualProps,
  secondTitle: {
    size: `5.8rem`,
    weight: `bold`,
    text: `Rebuy`,
    lineHeight: `1.3`,
    mobileMultiplier: 0.65,
    mobileText: `Rebuy`,
    smMobileMultiplier: 0.58,
  } as StrapiTextualProps,
  subTitle: {
    size: `2.4rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Visually, the Shopify CRO Platform,\nThe path to profitability starts with optimization.`,
    style: { textAlign: `center` },
    mobileMultiplier: 0.7,
    highlight: `optimization`,
    highlightStyle: { fontWeight: `bold` },
  } as StrapiTextualProps,
  mobileSubTitle: {
    size: `2.4rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Visually, the Shopify CRO\nPlatform, The path to profitability starts\n with optimization.`,
    mobileText: `Visually, the Shopify CRO\nPlatform, The path to profitability starts\n with optimization.`,
    style: { textAlign: `center` },
    mobileMultiplier: 0.7,
  } as StrapiTextualProps,
  secondSubTitle: {
    text: `A no-code CRO platform: sophisticated, but user friendly, empowering you\nto effortlessly boost your bottom line`,
    size: `1.8rem`,
    weight: `light`,
    lineHeight: `1.8`,
    mobileText: `A no-code CRO platform:
    sophisticated, but user friendly, empowering you
    to effortlessly boost your bottom line`,
    mobileMultiplier: 0.765,
  } as StrapiTextualProps,
  cta: {
    size: `2.2rem!important`,
    weight: `normal`,
    text: `Become a partner`,
    className: `btn primary-btn small`,
  } as StrapiTextualProps,
  review: {
    image: partnerImage,
    logo: verbalImage,
    name: `Caroline Dau`,
    job: `Senior Director Program
Management at`,
    text: `"Visually's platform is phenomenal in the way it has
allowed us to genuinely grow our client's businesses
through building smarter shopping experiences
with continuous testing and optimization. The ease
of use and the Visually intuitive editor cut us
significant go-live time"`,
  } as StrapiPartnerReviewCardProps,
  visuallyLogo: visuallyImage,
  otherCompanyLogo: rebuyimage,
};

export const ComparisonPageContent = {
  ComparisonByDataContent: {
    content: {
      'In short': {
        exampleA: `Full optimization platform\nA/B testing, personalization\nproduct recommendations, upsells, and\neven post purchase upsells`,
        exampleB: `Personalization, code-based A/B test\nplatform, upsells and cross-sells,\nand post-purchase engagement.`,
      },
      Implementation: {
        exampleA: `No implementation is needed!`,
        exampleB: `Long implementation,\nRequires technical skills`,
      },
      Price: {
        exampleA: `Starting at $15`,
        exampleB: `Starting at $99`,
      },
      'Price structure': {
        exampleA: `Based on order volume`,
        exampleB: `Based on order volume +\n% of generated revenue`,
      },
    },
  },

  ComparisonByFeatureContent: {
    features: {
      'Visual editor': {
        exampleA: true,
        exampleB: false,
      },
      'No-code platform': {
        exampleA: true,
        exampleB: `Low code`,
      },
      'Ease of use': {
        exampleA: `Intuitive, anyone can use`,
        exampleB: `Technical skills required`,
      },
      'Sitewide A/B testing': {
        exampleA: true,
        exampleB: false,
      },
      'Theme testing': {
        exampleA: true,
        exampleB: false,
      },
      'Image testing': {
        exampleA: true,
        exampleB: false,
      },
      'Third-party app testing': {
        exampleA: true,
        exampleB: false,
      },
      'Price testing': {
        exampleA: `For Shopify Plus merchants`,
        exampleB: false,
      },
      'Out of the box templates: content blocks': {
        exampleA: true,
        exampleB: false,
      },
      'Out of the box templates: sale boosters': {
        exampleA: true,
        exampleB: true,
      },
      'Ability to add custom code': {
        exampleA: true,
        exampleB: `Some support`,
      },
      'Content testing': {
        exampleA: true,
        exampleB: `Only on Rebuy widgets`,
      },
      'Behavioral Targeting': {
        exampleA: true,
        exampleB: `Limited`,
      },
      Personalization: {
        exampleA: true,
        exampleB: true,
      },
      'Product recommendations': {
        exampleA: true,
        exampleB: true,
      },
      Upsells: {
        exampleA: true,
        exampleB: true,
      },
      'Post-purchase upsells': {
        exampleA: true,
        exampleB: true,
      },
      'Checkout Extensibility': {
        exampleA: true,
        exampleB: true,
      },
      'GA4 integration': {
        exampleA: true,
        exampleB: true,
      },
      'Integration with your tech stack': {
        exampleA: true,
        exampleB: true,
      },
    },
  },

  ComparisonByDataHeaders: [
    { text: ``, styles: ComparisonTableTextStyles.title },
    { text: `Visually`, styles: ComparisonTableTextStyles.columnTitle },
    { text: `Rebuy`, styles: ComparisonTableTextStyles.columnTitle },
  ],

  ComparisonByFeatureHeaders: [
    { text: `Features`, styles: ComparisonTableTextStyles.title },
    { text: `Visually`, styles: ComparisonTableTextStyles.columnTitle },
    { text: `Rebuy`, styles: ComparisonTableTextStyles.columnTitle },
  ],

  ComparisonEasyOfUse: [
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/5dd9ded5-ee16-4844-8f47-b102897ab68f`,
      },
      text: {
        text: `Effortlessly execute A/B\ntests in just minutes`,
        size: `2.2rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.63,
        style: {
          fontWeight: `100`,
          lineHeight: 1.8,
        },
      } as StrapiTextualProps,
    },
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/e60fe045-ef41-4c2d-9393-9f44d796f039`,
      },
      text: {
        text: `Configuring a smart\nupselling algorithm has\nnever been easier`,
        size: `2.2rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.63,
        style: {
          fontWeight: `100`,
          lineHeight: 1.8,
        },
      } as StrapiTextualProps,
    },
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/b31cc0de-d3e4-4084-9e74-8b7b12a7d716`,
      },
      text: {
        text: `Quickly personalize\nexisting pages, apps, and\nworkflows.`,
        size: `2.2rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.63,
        style: {
          fontWeight: `100`,
          lineHeight: 1.8,
        },
      } as StrapiTextualProps,
    },
  ],

  ComparisonProductsAndFeaturesSection: {
    title: {
      text: `Product & Features`,
      weight: `bold`,
      size: `4.7rem`,
      mobileMultiplier: 0.55,
    } as StrapiTextualProps,
  },

  ComparisonEasyOfUseSecrionTitle: {
    title: {
      text: `Ease Of Use`,
      weight: `bold`,
      size: `5.5rem`,
      mobileMultiplier: 0.46,
    } as StrapiTextualProps,

    subTitle: {
      text: `Marketing teams worldwide turn to Visually everyday. It’s the platform of
    choice for the most innovative Shopify brands today.`,
      size: `2rem`,
      lineHeight: `1.35`,
      mobileMultiplier: 0.95,
    } as StrapiTextualProps,
  },

  ComparisonSectionHeader: {
    title: {
      text: `How does Visually compare to Rebuy`,
      weight: `bold`,
      size: `4.7rem`,
      mobileMultiplier: 0.5,
      mobileText: `How does Visually compare
    to Rebuy`,
    } as StrapiTextualProps,
  },
};

export const getVisuallyBtn = {
  text: `Try Visually for Free`,
  size: `2.2rem`,
  weight: `normal`,
  mobileText: `Try Visually for Free`,
  mobileMultiplier: 0.7,
} as StrapiTextualProps;

export const ComparisonJoinSectionContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `Ready to start disrupting?`,
    mobileText: `Ready to start disrupting?`,
    mobileMultiplier: 0.6,
    smMobileMultiplier: 0.55,
  } as StrapiTextualProps,
  cta: {
    text: `Join Now`,
  },
  subTitles: [
    {
      text: `Instant setup`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
    {
      text: `Free trial`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
    {
      text: `Zero commitment`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
  ],
};

export const ComparisonReviewsContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `What people are saying`,
  } as StrapiTextualProps,
  subHeader: {
    size: `2.8rem`,
    text: `Some of the most successful eCommerce teams in the world use Visually every day.
    Here’s what they have to say:`,
    style: {
      fontWeight: `200`,
    },
  } as StrapiTextualProps,
};

export const ComparisonJoinButtons = {
  getVisuallyButton: {
    href: `https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=HP`,
    text: `Get Visually for free`,
  },
  getExpertButton: {
    href: `/public/talk-to-expert`,
    text: `Talk to an expert`,
  },
};
